import React from "react"
import { Link } from "@reach/router"
import { FaCaretRight } from 'react-icons/fa';

function getURL(props){
  const api = process.env.GATSBY_API_URL
  var url = (props.listing && props.listing.images.length > 0) ? props.listing.images[0].originalPath : "../images/listing-3.jpg"
  return api + url
}

const ListingsPage = (props) => {
  const isSSR = typeof window === "undefined"
  return (
    <>
    {!isSSR && (
      <div className="listing-item">
        <Link style={{color: "#000"}} to={props.listing ? `/listings/listing-details/${props.listing.id}` : `/listings`}>
            <span className="thumb-info">
                <span className="thumb-info-wrapper">
                    <img src={getURL(props)} height="300px" alt="" />
                    <span className="thumb-info-listing-type">
                        {props.listing.available ? "for rent" : "recently rented"}
                    </span>
                </span>
                <span className="thumb-info-price">
                     $ { props.listing.price } / month
                    <FaCaretRight className="pull-right"/>
                </span>
                <span className="custom-thumb-info-title">
                    <span className="thumb-info-inner">{ props.listing.property.street + ", "}</span>
                    <span className="thumb-info-inner">{props.listing.property.city}</span>
                    <ul className="accommodations">
                        <li>
                            <span className="accomodation-title">
                                Beds:
                            </span>
                            <span className="accomodation-value">
                                { props.listing.property.bedroom }
                            </span>
                        </li>
                        <li>
                            <span className="accomodation-title">
                                Baths:
                            </span>
                            <span className="accomodation-value">
                                { props.listing.property.bathroom }
                            </span>
                        </li>
                        <li>
                            <span className="accomodation-title">
                                Sq Ft:
                            </span>
                            <span className="accomodation-value">
                                { props.listing.property.sqft }
                            </span>
                        </li>
                    </ul>
                </span>
            </span>
        </Link>
      </div>
      )}
      </>
    )
}


export default ListingsPage
